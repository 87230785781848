import { useForm } from '@abyss/web/hooks/useForm';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';
import { useShallow } from 'zustand/react/shallow';

import { ConstantsLagoon } from '../../../common/ConstantsLagoon';
import { useAdobePageTrackEvent } from '../../../hooks/adobeHook/useAdobePageTrackEvent';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { useLagoon } from '../../../hooks/useLagoon';
import {
  ProviderDetails,
  ProviderLocation,
} from '../../../models/ProviderDetails';
import { useAnalyticsStore } from '../../../store/useAnalyticsStore';
import { AnalyticsStore } from '../../../store/useAnalyticsStore/analyticsStore';
import { useTypeaheadStore } from '../../../store/useTypeaheadStore';
import { TypeaheadState } from '../../../store/useTypeaheadStore/typeaheadStore';
import { returnInclusionFlag } from '../../../utils/featureSuppress';
import { isVirtualCareAvailableInLocation } from '../../../utils/indicator.utils';
import {
  areAllProviderLocationsNonTieredCheck,
  getSelectedLocationIndex,
  providerHasLocations,
  showNonTierLocationContent,
} from '../../../utils/providerDetails.utils';
import { getCurrentMember } from '../../../utils/user.utils';
import { adobeLinkTrackEvent } from '../../AdobeTagging/adobeLinkTrackEvent';
import { Constants, LOB } from '../../Constants';
import { FeatureFlags } from '../../ConstantsFeatureFlags';
import { h2SmallerFontForResponsive, phoneOnly } from '../../ConstantsStyles';
import { LinkWithTracking } from '../../LinkWithTracking/LinkWithTracking';
import { MapViewLocationsWrap } from '../../MapViewLocationsWrap/MapViewLocationsWrap';
import { MapViewUpdateLocationButton } from '../../MapViewUpdateLocationButton/MapViewUpdateLocationButton';
import { getFeatureFlag } from '../../Utils';
import {
  convertProviderTypeToAdobeType,
  getSearchBlock,
} from '../../Utils/adobeTrackUtils/adobeTrackUtils';
import { ProviderScheduling } from '../ProviderLocationsDetails/ProviderScheduling';
import { InfoProvider } from './InfoProvider';
import { LocationsContentForPopOver } from './LocationsContentForPopOver';
import { NonTierOneLocation } from './NonTierOneLocation';
import { PopOver } from './PopOver';
import {
  Container,
  InnerContainer,
  LocationsHeader,
  MapViewBoxContainer,
  MapViewBoxContainerFullWidth,
  SectionContainer,
  ViewOnMapLabel,
} from './ProviderLocation.style';

type Props = {
  providerDetails: ProviderDetails;
  setUpdateLocation: (a: boolean) => void;
  navigateToDirections?: boolean;
  setNavigateToDirections: (a: boolean) => void;
  tabTitle?: string;
  isTiered?: boolean;
  isMapView?: boolean;
  setIsMapView: (a: boolean) => void;
};

const showNonTierLocationBadgeUSPUNET = (
  population,
  unetNonTierOneLocationTag,
  uspNonTierOneLocationTag,
  selectedLocation
) =>
  showNonTierLocationContent(
    population,
    unetNonTierOneLocationTag,
    uspNonTierOneLocationTag,
    !selectedLocation?.isTieredProvider
  );

export const doShowNonTieredAlert = (
  isTieredUser,
  isTieredProvider,
  showTierProviderTag
) => isTieredUser && !isTieredProvider && showTierProviderTag;
const getIndicatorsForSelectedLocationImpressions = (selectedLocation) => {
  const indicators: string[] = [];

  if (selectedLocation?.acceptingNewPatients)
    indicators.push(Constants.ADOBE_TRACKING.NEW_PATIENTS);
  if (isVirtualCareAvailableInLocation(selectedLocation))
    indicators.push(
      Constants.ADOBE_TRACKING.VIRTUAL_CARE_OFFERED_LOCAL_PROVIDER
    );
  if (selectedLocation?.costIndicator) {
    indicators.push(Constants.ADOBE_TRACKING.COST_ESTIMATE_DISPLAYED);
  }
  if (selectedLocation?.hasPCPServiceAvailability)
    indicators.push(Constants.ADOBE_TRACKING.PCP_SERVICES);

  if (selectedLocation?.hasEveningAppointments)
    indicators.push(Constants.ADOBE_TRACKING.EVENING_APPOINTMENT);
  return indicators?.join('|').toLocaleLowerCase();
};
const stickyButtonRowCheck = (hasMultipleLocations, mobileScreen) =>
  hasMultipleLocations && !mobileScreen;

const shouldTrackPage = (selectedLocation, isMapView) =>
  selectedLocation && !isMapView;

export const ProviderLocationsDetails = ({
  providerDetails,
  setUpdateLocation,
  navigateToDirections = false,
  setNavigateToDirections,
  tabTitle,
  isTiered,
  isMapView = false,
  setIsMapView,
}: Props) => {
  const locationsDetails = providerDetails?.providerLocations;
  const hasMultipleLocations = providerHasLocations(providerDetails);
  const [selectedId, setSelectedId] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    ''
  );
  const [directionsTriggeredFrom] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.DIRECTIONS_TRIGGERED_FROM,
    ''
  );

  const { navigate } = useRouter();

  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);
  const featureTierOneFlags = useLagoon('feature-flags')();
  const [viewMapEnhancementsFlag] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_LIST_VIEW_MAP_ENHANCEMENTS,
  ]);
  const showTierProviderTag = getFeatureFlag(
    featureTierOneFlags,
    FeatureFlags.FEATURE_FLAG_KEY_CHIP_MAP[
      Constants.CHIPS_CATEGORIES.PRIMARY_CARE
    ].TIER_ONE
  );

  const unetNonTierOneLocationTag = getFeatureFlag(
    featureTierOneFlags,
    ConstantsLagoon.FEATURE_FLAGS.UNET_NONTIER1_LOCATION
  );
  const uspNonTierOneLocationTag = getFeatureFlag(
    featureTierOneFlags,
    ConstantsLagoon.FEATURE_FLAGS.USP_TIER1_ENABLE
  );

  const intialIndex = getSelectedLocationIndex(locationsDetails, selectedId);

  const [selectedAddressIndex, setSelectedAddressIndex] =
    useState<number>(intialIndex);
  const [currentIndex, setCurrentIndex] = useState<number>(intialIndex);
  const [selectedLocation, setSelectedLocation] = useState<ProviderLocation>(
    locationsDetails?.[selectedAddressIndex]
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [mobileRouteView, setMobileRouteView] = useState<boolean>(false);
  const currentMember = getCurrentMember();

  const areAllProviderLocationsNonTiered =
    areAllProviderLocationsNonTieredCheck(locationsDetails);

  const showNonTierLocationBadge = showNonTierLocationBadgeUSPUNET(
    currentMember?.population,
    unetNonTierOneLocationTag,
    uspNonTierOneLocationTag,
    selectedLocation
  );

  const { typeaheadState } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      typeaheadState: state.typeaheadSearchStore,
    }))
  );
  const { linkName } = useAnalyticsStore(
    useShallow((state: AnalyticsStore) => ({
      linkName: state.analyticsState.linkName,
    }))
  );

  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: tabTitle,
    sitesectionLevel1: Constants.ADOBE_TRACKING.DETAILS_SITESECTION1,
    sitesectionLevel2: `${convertProviderTypeToAdobeType(
      providerDetails.providerType
    )} details`,
    impressionBlock: {
      type: convertProviderTypeToAdobeType(providerDetails.providerType),
      indicator: getIndicatorsForSelectedLocationImpressions(selectedLocation),
    },
    searchBlock: getSearchBlock(typeaheadState, linkName),
  });

  useEffect(() => {
    if (locationsDetails?.[selectedAddressIndex]) {
      const selectedIndex: string = selectedAddressIndex.toString();
      const location = locationsDetails[selectedIndex];
      const { locationId } = location;
      setSelectedLocation(location);
      setSelectedId(locationId);
    }
  }, [selectedAddressIndex]);

  useEffect(() => {
    if (locationsDetails) {
      const index = locationsDetails.findIndex(
        (item) => item.locationId === selectedLocation?.locationId
      );
      setSelectedAddressIndex(index);
      setCurrentIndex(index);
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (shouldTrackPage(selectedLocation, isMapView)) adobePageTrackEvent();
  }, [isMapView, selectedAddressIndex]);

  const handleHideMap = () => {
    adobeLinkTrackEvent({
      name: 'back',
      location: `body:${Constants.PROVIDER_DETAILS.VIEWONMAP}`,
      type: 'internal',
    });
    setIsMapView(false);
    setNavigateToDirections(false);

    if (
      directionsTriggeredFrom !==
      Constants.DETAILS_PAGE_HEADER_DIRECTIONS_BUTTON
    ) {
      navigate(-1);
    }
  };

  useEffect(() => {
    setIsMapView(navigateToDirections);
  }, [navigateToDirections, mobileScreen]);

  useEffect(() => {
    if (!mobileRouteView && mobileScreen && navigateToDirections)
      handleHideMap();
  }, [mobileRouteView]);

  const changeAriaLabelForAlert = () => {
    const elements = document.getElementsByClassName(
      'abyss-alert-close-button'
    );
    return elements?.[0]?.setAttribute('aria-label', 'Close Alert');
  };

  useEffect(() => {
    if (
      doShowNonTieredAlert(
        isTiered,
        selectedLocation?.isTieredProvider,
        showTierProviderTag
      )
    ) {
      changeAriaLabelForAlert();
    }
  }, [selectedLocation, showTierProviderTag, isTiered]);

  const form = useForm({
    defaultValues: {
      'radio-form': '',
    },
  });

  const handleViewMap = () => {
    setIsMapView(true);
  };

  const handleUpdateLocation = () => {
    adobeLinkTrackEvent({
      name: 'save location',
      location: `body:${Constants.PROVIDER_DETAILS.VIEWONMAP}`,
      type: 'internal',
    });
    setUpdateLocation(true);
    setIsMapView(false);
    setSelectedAddressIndex(currentIndex);
    setNavigateToDirections(false);
  };

  const handleSelectedLocation = (location, index) => {
    setSelectedLocation(location);
    setSelectedAddressIndex(index);
  };

  if (!selectedLocation) {
    return null;
  }

  const useStickyButtonRow = stickyButtonRowCheck(
    hasMultipleLocations,
    mobileScreen
  );

  const isSameLocationSelected = selectedAddressIndex === currentIndex;
  const includeDocAsap =
    currentMember?.lineOfBusiness === LOB.CNS
      ? returnInclusionFlag(
          ConstantsLagoon.FEATURE_INCLUSION_FLAGS.INCLUDE_DOC_ASAP
        )
      : true;
  return !isMapView ? (
    <Container
      data-auto-testid="provider-locations-details-section"
      data-testid="provider-locations-details-section"
    >
      <FormProvider state={form}>
        <SectionContainer>
          <LocationsHeader alignItems="flex-end">
            <Heading
              css={h2SmallerFontForResponsive}
              data-auto-testid="directory-update-title"
              data-testid="directory-update-title"
              display="h4"
              offset={1}
            >
              {t('PROVIDER_DETAILS.LOCATIONS')}
              {` (${providerDetails.providerLocations.length})`}
            </Heading>
            <Layout.Group css={{ marginTop: '10px' }}>
              <LinkWithTracking
                analyticsInfo={{
                  location: 'body:locations',
                  name: Constants.PROVIDER_DETAILS.VIEWONMAP,
                }}
                data-auto-testid="view-facility-locations-on-map"
                data-testid="view-facility-locations-on-map"
                href="#"
                isStandardAnchor
                onClick={handleViewMap}
              >
                <IconMaterial
                  color="$interactive1"
                  css={{
                    width: '24px',
                    height: '24px',
                    marginRight: '4px',
                    '@screen < $sm': {
                      width: '18px',
                      height: '18px',
                    },
                  }}
                  icon="map"
                />
                <ViewOnMapLabel color="$interactive1">
                  {t('PROVIDER_DETAILS.VIEWONMAP')}
                </ViewOnMapLabel>
              </LinkWithTracking>
            </Layout.Group>
          </LocationsHeader>
          <PopOver
            isOpen={isOpen}
            locationsContentForPopOver={
              <LocationsContentForPopOver
                isOpen={isOpen}
                locationsDetails={locationsDetails}
                mobileScreen={mobileScreen}
                selectedIndex={selectedAddressIndex}
                selectedLocation={selectedLocation}
                setIsOpen={setIsOpen}
                setSelectedLocation={handleSelectedLocation}
              />
            }
            locationsDetails={locationsDetails}
            selectedLocation={selectedLocation}
            setIsOpen={setIsOpen}
          />
          <NonTierOneLocation
            areAllProviderLocationsNonTiered={areAllProviderLocationsNonTiered}
            showNonTierLocationBadge={showNonTierLocationBadge}
          />
        </SectionContainer>
      </FormProvider>
      {!currentMember?.isPreEffective && includeDocAsap && (
        <ProviderScheduling
          isTieredDataCard={isTiered}
          locationId={selectedLocation.locationKey}
          providerDetails={providerDetails}
          selectedLocation={selectedLocation}
        />
      )}
      <InnerContainer>
        <InfoProvider
          providerDetails={providerDetails}
          selectedLocation={selectedLocation}
        />
      </InnerContainer>
    </Container>
  ) : (
    <Container
      data-auto-testid="provider-locations-details-section"
      data-testid="provider-locations-details-section"
      tabIndex="0"
    >
      {viewMapEnhancementsFlag ? (
        <MapViewBoxContainerFullWidth>
          <MapViewLocationsWrap
            directionsButtonClicked={navigateToDirections}
            handleGoBack={handleHideMap}
            handleUpdateLocation={handleUpdateLocation}
            hasMultipleLocations={hasMultipleLocations}
            isMapView={isMapView}
            mobileRouteView={mobileRouteView}
            mobileScreen={mobileScreen}
            providerDetails={providerDetails}
            setCurrentIndex={setCurrentIndex}
            setIsMapView={setIsMapView}
            setMobileRouteView={setMobileRouteView}
            tabTitle={tabTitle}
            updateLocationButton={
              <MapViewUpdateLocationButton
                handleSaveLocation={handleUpdateLocation}
                isSameLocationSelected={isSameLocationSelected}
              />
            }
            useStickyButtonRow={useStickyButtonRow}
          />
        </MapViewBoxContainerFullWidth>
      ) : (
        <MapViewBoxContainer>
          <MapViewLocationsWrap
            directionsButtonClicked={navigateToDirections}
            handleGoBack={handleHideMap}
            handleUpdateLocation={handleUpdateLocation}
            hasMultipleLocations={hasMultipleLocations}
            isMapView={isMapView}
            mobileRouteView={mobileRouteView}
            mobileScreen={mobileScreen}
            providerDetails={providerDetails}
            setCurrentIndex={setCurrentIndex}
            setIsMapView={setIsMapView}
            setMobileRouteView={setMobileRouteView}
            tabTitle={tabTitle}
            updateLocationButton={
              <MapViewUpdateLocationButton
                handleSaveLocation={handleUpdateLocation}
                isSameLocationSelected={isSameLocationSelected}
              />
            }
            useStickyButtonRow={useStickyButtonRow}
          />
        </MapViewBoxContainer>
      )}
    </Container>
  );
};
