import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { Popover } from '@abyss/web/ui/Popover';
import { Text } from '@abyss/web/ui/Text';

export const MobileViewHeadingNavigationWrap = styled('div', {
  display: 'flex',
  width: '100%',
  padding: '16px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px',
  backgroundColor: '$primary1',
});

export const PopoverProviderName = styled(Popover, {
  zIndex: '1 !important',

  '&.abyss-popover-content-container': {
    textWrap: 'wrap',
    maxWidth: '205px',
  },
});

export const MobileViewHeadingInnerContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  alignSelf: 'stretch',
});

export const MobileViewHeadingContent = styled(Flex, {
  alignSelf: 'stretch',
  display: 'flex',
  padding: '6px 0px',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
  borderRadius: '12px',
  backgroundColor: '$white',
  color: '$gray7',
  boxShadow: '0px 2px 6px 0px rgba(25, 25, 26, 0.16)',
  width: '100%',
});

export const ProfilePicture = styled('div', {
  display: 'flex',
  width: '48px',
  height: '48px',
  minWidth: '48px',
  minHeight: '48px',
  borderRadius: '100px',
  overflow: 'hidden',
  marginLeft: '16px',
  marginRight: '10px',
  padding: '4px',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'lightgray 50% cover no-repeat',
});

export const ProviderProfileInfo = styled(Flex, {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

export const ProviderTitle = styled('div', {
  color: '$gray7',
  fontSize: '18px',
  fontWeight: '700',
  lineHeight: '24px',
});

export const ProviderSubTitle = styled('div', {
  fontSize: '12.64px',
  color: '$gray6',
  fontWeight: '500',
  lineHeight: '16px',
});

export const ChooseLocationCardsContainer = styled(Flex, {
  marginTop: '6px',
  maxHeight: '463px',
  overflow: 'auto',
  gap: '12px',
  '@screen < $sm': {
    gap: '16px',
    marginTop: '0px !important',
  },
  '.location-cards-container': {
    width: '100%',
  },
});

export const ChooseALocationHeadingWrap = styled('div', {
  marginTop: '16px',
});

export const ChooseLocationLabel = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '25.63px',

    '@screen < $sm': {
      fontSize: '20.25px',
    },
  },
});

export const ChooseLocationRow = styled(Flex, {
  maxWidth: '408px',
  height: '83px',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const LocationsWrapColumn = styled('div', {
  height: '615px',
  position: 'absolute',
  display: 'inline-block',
  overflow: 'visible',
  backgroundColor: '$primary2',
  zIndex: 10,
  boxShadow: '10px 0px 5px -5px rgba(0, 0, 0, 0.2)',
  paddingRight: '$lg',
  '@screen >= $sm': { maxWidth: '432px' },
  '@screen < $sm': {
    width: '100%',
    height: '532px',
    boxShadow: 'unset',
    padding: '0 $md',
  },
});

export const MapViewWrap = styled('div', {
  dynamic: ({
    cssProps: {
      viewMapEnhancementsFlag = false,
      closeSidePanel,
      mobileRouteView,
    },
  }) => ({
    '.abyss-card-root': {
      /**
       * HERE calc function needs to subtract the sidepanel width 432px - the container wrapper padding 76px
       * 432px - 76px = 356px
       * */
      width: closeSidePanel ? '100%' : 'calc(100% - 356px)',
      marginLeft: closeSidePanel || viewMapEnhancementsFlag ? '0' : '432px',
    },
    '@screen < $sm': {
      '.abyss-card-root ': {
        height: mobileRouteView ? '100vh' : 'calc(100vh - 92px)',
        '.map-container': {
          position: 'relative',
          top: mobileRouteView ? '244px' : '',
          height: mobileRouteView
            ? 'calc(100vh - 244px)'
            : 'calc(100vh - 92px)',
        },
      },
    },
  }),
  '.abyss-card-root': {
    height: '615px',
    border: 'none',
    boxShadow: 'unset',
    '.map-container': {
      height: '615px',
      width: '100%',
    },
  },
  '@screen < $sm': {
    '.abyss-card-root ': {
      width: '100vw !important',
      margin: '-1px !important',
      '.map-container': {
        width: '100vw',
      },
    },
  },
  '.mapboxgl-ctrl-bottom-left': {
    display: 'none',
  },
  '.mapboxgl-ctrl-bottom-right': {
    display: 'none',
  },
});

export const MobileMapViewWrap = styled('div', {
  '@screen >= $md': {
    paddingRight: '84px',
  },
  '&.show-mobile-map-view': {
    position: 'relative',
    paddingRight: 0,
    '.locations-wrap-column': {
      width: '100%',
      background: 'transparent',
      bottom: 0,
      height: '180px',
      padding: 0,
      '@screen < $sm': {
        padding: '0px !important',
        height: 'auto',
        marginBottom: '20px !important',
      },
      '.choose-location-container': {
        maxWidth: 'unset',
        flexWrap: 'unset',
        '@screen < $sm': {
          padding: 0,
        },
      },
      '.abyss-card-root .map-container': {
        borderRadius: '0px',
      },
    },
  },
});

export const SidePaneCloseButton = styled(Flex, {
  right: '-26px',
  top: '24px',
  position: 'absolute',
  zIndex: 1,
  height: '42px',
  width: '26px',
  backgroundColor: 'white',
  borderRadius: '0 4px 4px 0',
  border: '1px solid #E5E5E5',
  borderLeft: 'none',
  boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.2)',
  cursor: 'pointer',
  '@screen < $sm': {
    display: 'none',
  },
});

export const ViewMapButton = styled(Button, {
  color: '$interactive1',
  '&.abyss-button-root': {
    height: '20px',
    padding: 0,
  },

  '&.abyss-button-content-container': {
    fontSize: '$xs',
    lineHeight: '16px',
  },
  '.abyss-insert-element-before': {
    paddingRight: '6px !important',
  },
});
