import { storage } from '@abyss/web/tools/storage';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { Fragment, useEffect, useState } from 'react';

import { Constants, LOB } from '../../../../common/Constants';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { MOCK_MEMBERS_INFO } from '../../../../common/MockMemberInfo';
import { dateGroupByYearMonthDay } from '../../../../common/Utils/datesUtils/formatDate';
import { getFeatureFlag } from '../../../../common/Utils/getFeatureFlag';
import { useLagoon } from '../../../../hooks/useLagoon';
import { useOBAPI } from '../../../../hooks/useOBAPI';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { loginNotRequired } from '../../../../utils/session.utils';
import { modifyRulesPackageKey } from '../../../../utils/user.utils';
import { IChildrenProp } from '../../types';

export const Obapi = ({ children }: IChildrenProp) => {
  const { bigN } = useStore(StoreKeys.PLAN_SELECTOR);
  const setMemberState = useStore(StoreKeys.SET_OBAPI_MEMBERS);
  const isLocal = loginNotRequired();

  const [members, setMembers] = useOBAPI({});
  const { isLoading } = members;
  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const tierBenefits = useLagoon('tier-benefit')();
  const requestReciprocityId = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.REQUEST_RECIPROCITY_ID
  );
  const enableMnrCosmos = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_MNR_COSMOS
  );

  const enableCns = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_CNS
  );
  const showIsPreEffectiveIndicator = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.SHOW_ISPREEFFECTIVE_INDICATOR
  );

  const policyFilterToggle = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.MEMBER_INFO_POLICY_FILTER
  );

  const { DEMO_MEMBER_INFO, USE_PRE_PROD } = Constants.STORAGE_KEYS.SESSION;

  const [enablePreProd] = useState(storage.session.get(USE_PRE_PROD, false));
  useEffect(() => {
    storage.session.set(USE_PRE_PROD, enablePreProd);
  }, [enablePreProd]);

  const {
    firstName,
    lastName,
    groupNumber,
    memberId,
    dob,
    id,
    userName,
    portalSource,
    lob,
    usePreProd,
  } = storage.session.get(DEMO_MEMBER_INFO) || {};

  const fpcPsxRedirection = useLagoon(
    Constants.LAGOON_TABLE.FPC_PSX_REDIRECTION
  )();
  const eligiblePolicyIds =
    fpcPsxRedirection?.[0]?.eligiblePolicyNumbers?.split(',') || [];

  useEffect(() => {
    if (userName || memberId) {
      setMembers({
        variables: {
          ...((lob === LOB.ENI || lob === LOB.CNS || lob === LOB.IFP) && {
            firstName,
            lastName,
            groupNumber,
            memberId,
            dob,
          }),
          ...(lob === LOB.MNR && {
            id,
            userName,
            portalSource,
          }),
          lob,
          usePreProd,
          enableMnrCosmos,
          enableCns,
          requestReciprocityId,
          eligiblePolicyIds,
          showIsPreEffectiveIndicator,
          policyFilterToggle,
        },
      });
    } else if (
      !isLocal &&
      (bigN?.MemberNumber?.length || bigN?.userName?.length)
    ) {
      setMembers({
        variables: {
          firstName: bigN.FirstName,
          lastName: bigN.LastName,
          groupNumber: bigN.GroupNumber,
          memberId: bigN.MemberNumber,
          dob: bigN.DOB ? dateGroupByYearMonthDay(bigN.DOB) : undefined,
          id: bigN.id,
          userName: bigN.userName,
          portalSource: bigN.portalSource,
          lob: '',
          requestReciprocityId,
          eligiblePolicyIds,
          usePreProd: enablePreProd,
          enableMnrCosmos,
          enableCns,
          showIsPreEffectiveIndicator,
          policyFilterToggle,
        },
      });
    }
  }, [bigN]);

  useEffect(() => {
    if (members.data) {
      const memberInfo = modifyRulesPackageKey(
        members.data.memberInfo,
        tierBenefits
      );
      setMemberState(memberInfo);
    }
    if (isLocal && !members.data) {
      setMemberState(MOCK_MEMBERS_INFO);
    }
  }, [members]);

  return (
    <Fragment>
      {isLoading ? (
        <LoadingSpinner
          ariaLoadingLabel="Loading page"
          isLoading={isLoading}
          size="$sm"
        />
      ) : (
        <Fragment>{children}</Fragment>
      )}
    </Fragment>
  );
};
