import { getModuleOrDefault } from '@optum-fpc-psx-mobile-apps/mapping-files-translation';
import flatten from 'lodash/flatten';

import {
  CareCategories,
  Constants,
  EAP_LABEL,
  EAP_OPTION_LABEL,
  FACILITIES_B,
  FACILITIES_M,
  FACILITIES_M1,
  ProviderTypes,
  ReverseCoverageTypesCodes,
  TypeaheadProviderTypes,
  preferredFacilitiesPseudoRollupCodes,
} from '../../../../common/Constants';
import {
  isCnsMember,
  isMnrMember,
} from '../../../../common/Utils/memberUtils/memberUtils';
import { useTypeaheadPseudoRollupTable } from '../../../../hooks/useTypeaheadPseudoRollup';
import { ChipCategory } from './categoryCountTypes';
import { modifyCategory } from './modifyVisionCategory.utils';

const getModifiedPayload = (
  modifiedPayload,
  uesTranslation,
  codeByGroup,
  keyword,
  typeaheadAoeCodes,
  enableBHAoeTypeahead = false
) => {
  let payload = modifiedPayload.map((category) => {
    // rollup code for Eye Care Location is currently mapped with medical and hence below overridding is requred.
    category.includeSpecialityRollupCodes =
      keyword !== Constants.EYE_CARE_LOCATIONS
        ? []
        : category.includeSpecialityRollupCodes.filter((item: string) =>
            codeByGroup.includes(item)
          );
    return category;
  });
  codeByGroup.forEach((value) => {
    const foundSpecialty = uesTranslation?.[value?.trim()];
    const isBHFacilityFound = foundSpecialty?.find(
      (specialty) =>
        specialty.type === TypeaheadProviderTypes.FACILITY &&
        specialty.coverageType ===
          ReverseCoverageTypesCodes['BEHAVIORAL HEALTH']
    );
    if (isBHFacilityFound) {
      payload.forEach((category) => {
        if (category.uesType === TypeaheadProviderTypes.BEHAVIORAL_HEALTH) {
          // eslint-disable-next-line no-param-reassign
          category.includeSpecialityRollupCodes.push(value);
        }
      });
    }
    payload.forEach((category) => {
      foundSpecialty?.forEach((specialty) => {
        if (category.uesType === specialty?.type) {
          // eslint-disable-next-line no-param-reassign
          category.includeSpecialityRollupCodes.push(value);
        }
      });
      if (
        isBHAoeSearch(category.label, enableBHAoeTypeahead, typeaheadAoeCodes)
      ) {
        category.aoeCodes = typeaheadAoeCodes;
        category.prioritizeAoe = true;
      }
    });
  });

  payload = payload.filter(
    (eachCategory) =>
      eachCategory.includeSpecialityRollupCodes.length !== 0 ||
      (eachCategory.aoeCodes && eachCategory.aoeCodes.length !== 0)
  );
  return payload;
};

export const getModifiedPayloadForProviderSearch = (
  payloadWithRecriprocityId,
  codeByGroup,
  uesTranslation,
  search,
  keyword,
  isUserTextSearch,
  coverageTypes,
  typeaheadAoeCodes = [],
  enableBHAoeTypeahead = false,
  searchType,
  behavioralFacilityProgram = ''
) => {
  let modifiedPayload = JSON.parse(JSON.stringify(payloadWithRecriprocityId));
  if (
    behavioralFacilityProgram &&
    !codeByGroup &&
    searchType === Constants.SEARCH_TYPES.SPECIALTY
  ) {
    modifiedPayload = modifiedPayload.filter(
      (eachCategory) => eachCategory.label === CareCategories.BEHAVIORAL_HEALTH
    );
    if (modifiedPayload.length > 0) {
      modifiedPayload[0].behavioralFacilityProgram = behavioralFacilityProgram;
    }
    return modifiedPayload;
  } else if (!codeByGroup) {
    const rollCodes: string[] = [];
    const excludeRollCodes: string[] = [];
    const getIncludeSpecialityRollupCodes = (coverages) =>
      flatten(
        coverages?.map((coverage) => {
          switch (coverage) {
            case 'B':
              return [...rollCodes, ...FACILITIES_B];
            case 'M':
              return [...rollCodes, ...FACILITIES_M];
            default:
              return rollCodes;
          }
        })
      );
    const getExcludeOrgTypeCodes = (coverages) =>
      flatten(
        coverages?.map((coverageType) => {
          if (coverageType === 'M' && coverageTypes?.includes('M')) {
            return [...excludeRollCodes, ...FACILITIES_M1];
          }
          return excludeRollCodes;
        })
      );
    modifiedPayload = modifiedPayload.map((category) => ({
      ...category,
      name: search,
      ...(category.label === CareCategories.FACILITIES && {
        includeSpecialityRollupCodes: isUserTextSearch
          ? []
          : getIncludeSpecialityRollupCodes(category?.coverages),
        excludeOrgTypeCodes: getExcludeOrgTypeCodes(category?.coverages),
      }),
    }));
  } else {
    modifiedPayload = getModifiedPayload(
      modifiedPayload,
      uesTranslation,
      codeByGroup,
      keyword,
      typeaheadAoeCodes,
      enableBHAoeTypeahead
    );
  }

  return modifiedPayload;
};

const getIncludeSpecialityRollupCodes = (coverages) => {
  const rollCodes: string[] = [];
  return flatten(
    coverages?.map((coverage) => {
      switch (coverage) {
        case 'B':
          return [...rollCodes, ...FACILITIES_B];
        case 'M':
          return [...rollCodes, ...FACILITIES_M];
        default:
          return rollCodes;
      }
    })
  );
};

export const getModifiedPayloadForCategoriesCount = (
  payloadWithRecriprocityId,
  codeByGroup,
  uesTranslation,
  search,
  keyword,
  isPseudoRollupSearch,
  isUserTextSearch,
  coverageTypes,
  isConditionSearch = false,
  typeaheadAoeCodes = [],
  enableBHAoeTypeahead = false,
  behavioralFacilityProgram = ''
) => {
  let modifiedPayload = JSON.parse(JSON.stringify(payloadWithRecriprocityId));
  if (isPseudoRollupSearch) {
    codeByGroup = undefined;
  }
  if (
    (behavioralFacilityProgram || typeaheadAoeCodes?.length > 0) &&
    !codeByGroup
  ) {
    modifiedPayload = modifiedPayload.filter(
      (eachCategory) => eachCategory.label === CareCategories.BEHAVIORAL_HEALTH
    );
    if (typeaheadAoeCodes?.length > 0) {
      modifiedPayload[0].aoeCodes = typeaheadAoeCodes;
      modifiedPayload[0].prioritizeAoe = true;
    }
    return modifiedPayload;
  } else if (!codeByGroup) {
    const excludeRollCodes: string[] = [];
    const getExcludeOrgTypeCodes = (
      coverages,
      orgTypeCodesToExclude: string[]
    ) =>
      flatten(
        coverages?.map((coverageType) => {
          if (coverageType === 'M' && coverageTypes?.includes('M')) {
            return [
              ...excludeRollCodes,
              ...orgTypeCodesToExclude,
              ...FACILITIES_M1,
            ];
          }
          return [...excludeRollCodes, ...orgTypeCodesToExclude];
        })
      );
    modifiedPayload = modifiedPayload.map((category) => ({
      ...modifyCategory(
        category,
        search,
        isPseudoRollupSearch,
        isConditionSearch,
        typeaheadAoeCodes,
        isBHAoeSearch(category.label, enableBHAoeTypeahead, typeaheadAoeCodes)
      ),
      ...(category.label === CareCategories.FACILITIES && {
        includeSpecialityRollupCodes: isUserTextSearch
          ? []
          : getIncludeSpecialityRollupCodes(category?.coverages),
        excludeOrgTypeCodes: getExcludeOrgTypeCodes(
          category?.coverages,
          category.excludeOrgTypeCodes || []
        ),
      }),
    }));
  } else {
    modifiedPayload = getModifiedPayload(
      modifiedPayload,
      uesTranslation,
      codeByGroup,
      keyword,
      typeaheadAoeCodes,
      enableBHAoeTypeahead
    );
    modifiedPayload = modifiedPayload.map((eachCategory) => ({
      ...eachCategory,
      uesType: undefined,
      includeSpecialityRollupCodes: [
        ...new Set(eachCategory.includeSpecialityRollupCodes),
      ],
    }));
  }
  return modifiedPayload;
};

export const IsSearchResultsLoading = (
  orgIsLoading: boolean,
  providerIsLoading: boolean,
  medGrpIsLoading: boolean,
  noCategoryDataFound: boolean,
  isCategoryCountLoading: boolean,
  selectedCategoryLabel: string | undefined,
  isFetchingData: boolean,
  isResultsLoading: boolean
) => {
  const isLoading =
    (isFetchingData ||
      orgIsLoading ||
      (selectedCategoryLabel !== CareCategories.FACILITIES &&
        (providerIsLoading || medGrpIsLoading))) &&
    (!noCategoryDataFound || isCategoryCountLoading);
  return isLoading || isResultsLoading;
};
export const hideResultSection = (
  selectedCategoryChip: string | undefined,
  setShowFacilities: (a: boolean) => void,
  setShowProviderGroups: (a: boolean) => void,
  setShowProviders: (a: boolean) => void
) => {
  if (
    selectedCategoryChip === CareCategories.MEDICAL_SPECIALISTS ||
    selectedCategoryChip === CareCategories.DENTAL
  ) {
    setShowFacilities(false);
  }
  if (
    selectedCategoryChip === CareCategories.VISION ||
    selectedCategoryChip === CareCategories.DENTAL ||
    selectedCategoryChip === CareCategories.FACILITIES ||
    selectedCategoryChip === CareCategories.HEALTH_SUPPLIES
  ) {
    setShowProviderGroups(false);
  }
  if (
    selectedCategoryChip === CareCategories.FACILITIES ||
    selectedCategoryChip === CareCategories.HEALTH_SUPPLIES
  ) {
    setShowProviders(false);
  }
};

export const getFirstEnabledChip = (getChipCategoriesCount: ChipCategory[]) => {
  const firstChipId = getChipCategoriesCount?.find(
    (item: { chipEnabled: boolean; chipCategory: string }) => item.chipEnabled
  )?.chipCategory;
  const cValue = Constants.CARE_CATEGORIES_TYPEAHEAD.find(
    (item) => item.id === firstChipId
  )?.value;
  return cValue || '';
};

export const combineProvidersAcrossSections = (
  data: any,
  selectOnlyFacility: boolean
) => {
  let providers: any[] = [];
  if (data?.providers?.length > 0 && !selectOnlyFacility) {
    providers = [
      ...providers,
      ...data.providers.slice(
        0,
        Constants.RESULT_SECTION
          .MAX_PROVIDERS_TO_DISPLAY_IN_MIXED_RESULTS_SCREEN
      ),
    ];
  }
  if (data?.medicalGroup?.length > 0 && !selectOnlyFacility) {
    providers = [
      ...providers,
      ...data.medicalGroup.slice(
        0,
        Constants.RESULT_SECTION
          .MAX_PROVIDERS_TO_DISPLAY_IN_MIXED_RESULTS_SCREEN
      ),
    ];
  }
  if (data?.facilities?.length > 0) {
    providers = [
      ...providers,
      ...data.facilities.slice(
        0,
        Constants.RESULT_SECTION
          .MAX_PROVIDERS_TO_DISPLAY_IN_MIXED_RESULTS_SCREEN
      ),
    ];
  }

  return providers;
};
export const getAllProvidersData = (data: any, contextValue: string) => {
  if (
    contextValue === CareCategories.FACILITIES ||
    contextValue === CareCategories.HEALTH_SUPPLIES
  ) {
    data.totalCountPractitioners = 0;
    data.totalCountMedicalGroup = 0;
    return combineProvidersAcrossSections(data, true);
  }
  return [];
};

export const getPreferredFacilityFilter = (
  pseudoVerticals: any,
  pseudoVerticalsDetails: any
) => {
  if (
    pseudoVerticals &&
    preferredFacilitiesPseudoRollupCodes?.includes(pseudoVerticals)
  ) {
    return pseudoVerticalsDetails?.filterName?.split(',');
  }
  return [];
};

export const compareAndReturn = (a: any, b: any) => a || b;

export const checkForPseudoRollupCodes = (
  includeSpecialityRollupCodes: string
) => {
  const specialityRollupCodes = includeSpecialityRollupCodes?.split(',');
  const pseudoRollupCodeTable = useTypeaheadPseudoRollupTable();
  const filteredTable = pseudoRollupCodeTable?.filter(
    (item) => item.isPseudoRollupSearch
  );
  const hasOnlyPseudoRollupCodes = specialityRollupCodes?.every((code) =>
    filteredTable.some((row) => row.pseudoRollupCode === code)
  );
  return hasOnlyPseudoRollupCodes;
};

export const getDntFiltersFromQueryParams = (
  queryParams: string,
  deepLinkMapping: any
) => {
  const dntFilters = [] as string[];
  deepLinkMapping.forEach((item) => {
    if (queryParams?.toLowerCase().includes(item.dntName?.toLowerCase())) {
      item.dntFilters && dntFilters.push(item.dntFilters?.split(','));
    }
  });
  return dntFilters.flat();
};

export const setSelectedFiltersFromDntLabel = (
  selectedFiltersIds: string[],
  filtersArray: any
) => {
  const selectedFilters = filtersArray?.filter((item) =>
    selectedFiltersIds.includes(item.dnt_label)
  );
  return selectedFilters;
};

export const getAOEOrSpecialityById = (elementIds, translationObj) => {
  // Map elementIds to new array of objects
  const aoeOrSpecialityObjArray = elementIds.map((id) => {
    const valueKeys: string[] = [];
    for (const key in translationObj) {
      if (translationObj[key] === id) {
        valueKeys.push(key);
      }
    }
    return {
      label: id,
      value: valueKeys.join(','),
      dnt_label: id,
    };
  });

  return aoeOrSpecialityObjArray;
};

export const isShowProviderGroups = (showProviderGroups, lob, choosePCP) =>
  showProviderGroups && !((isMnrMember(lob) || isCnsMember(lob)) && choosePCP);

export const isBHAoeSearch = (
  category: string,
  enableBHAoeTypeahead: boolean,
  typeaheadAoeCodes: string[]
): boolean =>
  category === CareCategories.BEHAVIORAL_HEALTH &&
  enableBHAoeTypeahead &&
  typeaheadAoeCodes?.length > 0;

export const getAoeCodesForEAP = () => {
  const aoeTranslations = getModuleOrDefault('en', 'aoeTranslations');
  return Object.keys(aoeTranslations).filter(
    (key) => aoeTranslations[key] === EAP_OPTION_LABEL
  );
};

export const getUpdatedAoeCodesWithEAP = (
  eapBHProgramFlag: boolean = false,
  preSelectEapFilter: boolean = false,
  typeaheadAoeCodes: string[] = [],
  conditionAoeCodes: string[] = [],
  providerType?: string
) => {
  const checkedAoeCodes =
    typeaheadAoeCodes?.length > 0 ? typeaheadAoeCodes : conditionAoeCodes;

  const eapCodes: string[] = getAoeCodesForEAP();

  const updatedAoeCodes = checkedAoeCodes?.concat(
    !eapBHProgramFlag &&
      preSelectEapFilter &&
      providerType !== ProviderTypes.ORGANIZATION
      ? eapCodes
      : []
  );
  return [...new Set(updatedAoeCodes)];
};

export const getUpdatedAoeCodesWithFilterOptions = (
  typeaheadAoeCodes: string[] = [],
  aoeOptionsCodes: string[] = [],
  eapBHProgramFlag: boolean = false
) => {
  const flattenAoeOptionsCodes = aoeOptionsCodes
    .map((item) => item.split(','))
    .flat();

  const concatedAoeCodes = [
    ...new Set(typeaheadAoeCodes?.concat(flattenAoeOptionsCodes)),
  ];

  const eapCodes: string[] = getAoeCodesForEAP();
  const excludedEapFromAoeCodes = concatedAoeCodes.filter(
    (code) => !eapCodes.includes(code)
  );

  return eapBHProgramFlag ? excludedEapFromAoeCodes : concatedAoeCodes;
};

export const getEapBHProgramId = () => {
  const behaviouralProgramsTranslations = getModuleOrDefault(
    'en',
    'behaviouralProgramsTranslations'
  );
  return Object.keys(behaviouralProgramsTranslations).find(
    (key) => behaviouralProgramsTranslations[key] === EAP_LABEL
  );
};

export const setBehavioralProgramId = (
  eapBHProgramFlag: boolean = false,
  preSelectEapFilter: boolean = false,
  providerType?: string
) => {
  const eapBHProgramId = getEapBHProgramId();

  return eapBHProgramFlag &&
    preSelectEapFilter &&
    providerType !== ProviderTypes.ORGANIZATION
    ? [eapBHProgramId]
    : undefined;
};

export const setBehavioralProgramIdForEapFilter = (
  eapBHProgramFlag: boolean = false,
  selectedAoeOptions?: any[]
) => {
  const eapBHProgramId = getEapBHProgramId();

  const isEapFilterSelected = selectedAoeOptions?.some(
    (item) => item.dnt_label === EAP_OPTION_LABEL
  );

  return eapBHProgramFlag && isEapFilterSelected ? [eapBHProgramId] : undefined;
};
