import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useShallow } from 'zustand/react/shallow';

import { adobeLinkTrackEvent } from '../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../../../../common/Constants';
import { mobileOnly } from '../../../../common/ConstantsStyles';
import { ContentWrapper } from '../../../../common/ContentWrapper';
import { ContainerForAllSectionsStyled } from '../../../../common/ProviderDetailTabs/ProviderDetailTabs.styled';
import { useAdobePageTrackEvent } from '../../../../hooks/adobeHook/useAdobePageTrackEvent';
import { useLagoon } from '../../../../hooks/useLagoon';
import { useTypeaheadStore } from '../../../../store/useTypeaheadStore';
import { TypeaheadState } from '../../../../store/useTypeaheadStore/typeaheadStore';
import {
  getClaimOrEligibilitySystemTypeCode,
  getCurrentMember,
  getPolicyNumber,
} from '../../../../utils/user.utils';
import {
  StyledVisionCareBox,
  StyledVisionCareButton,
} from './ExternalVisionCard.styled';

/**
 * Checks if the given URL is an absolute URL (starts with "http") or a relative URL.
 * @param url - The URL to check.
 * @returns True if the URL is absolute, false if it is relative.
 */
const isAbsoluteUrl = (url: string): boolean => url.startsWith('http');

const getSpecialtyCategory = (url: string): string | null => {
  const params = new URLSearchParams(url.split('?')[1]);
  return params.get('specialtyCategory');
};

export const ExternalVisionCard = () => {
  const currentMember = getCurrentMember();
  const mobileScreen = useMediaQuery(mobileOnly);
  const visionCareCard = useLagoon(Constants.LAGOON_TABLE.VISION_CARE_CARD)();
  const visionCare = useLagoon(Constants.LAGOON_TABLE.VISION_CARE)();

  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: 'vision overview',
    impressionBlock: {
      type: 'provider',
    },
  });

  const { setTypeaheadSearchStore } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      setTypeaheadSearchStore: state.setTypeaheadSearchStore,
    }))
  );

  const visionCoverage = getClaimOrEligibilitySystemTypeCode(
    currentMember,
    'V'
  );
  const memberSitusState = visionCoverage?.situsState;
  const memberPolicyId = getPolicyNumber(currentMember, 'V');

  const doCompare = (contentVal: string, memberVal: string) => {
    if (contentVal?.includes(',')) {
      const contentValAr = contentVal.split(',');
      return contentValAr.includes(memberVal);
    }
    return memberVal === contentVal;
  };

  // Find Vision Care Card content that matches on situsState and policyID if both defined, or on either one if only one
  // is defined. If no matches found, use the default content (no situsState or policyIDs specified).
  const planSpecificContent = visionCareCard.find((content) => {
    const { policyIDs, situsState } = content;
    if (situsState) {
      if (!doCompare(situsState, memberSitusState)) {
        return false;
      }

      if (policyIDs) {
        return doCompare(policyIDs, memberPolicyId);
      }
      return true;
    }

    if (policyIDs) {
      return doCompare(policyIDs, memberPolicyId);
    }
    return false;
  });

  const defaultContent = visionCareCard.find(
    (content) => !content.situsState && !content.policyIDs
  );
  const visionCardContent = planSpecificContent || defaultContent;

  if (!visionCardContent) {
    return null;
  }

  const { title, message, url, buttonTitle } = visionCardContent;
  const boxWidth = mobileScreen ? '100%' : '588px';

  const handleOnClick = (): void => {
    if (!isAbsoluteUrl(url)) {
      setTypeaheadSearchStore({
        combinedIncludeSpecialityRollupCodes: specialtyCode,
        includeSpecialityRollupCodes: specialtyCode,
      });
    }

    adobeLinkTrackEvent({
      name: buttonTitle,
      location: title,
      type: 'external',
    });
    adobePageTrackEvent();
  };

  const specialtyCategory = getSpecialtyCategory(url);

  const specialtyCode =
    visionCare.find(
      ({ DNT_heading }) =>
        DNT_heading.toLowerCase() === specialtyCategory?.toLowerCase()
    )?.specialtyCode || '';

  return (
    <ContainerForAllSectionsStyled>
      <ContentWrapper>
        <StyledVisionCareBox
          color="$tint2"
          data-testid="external-vision-card-container"
          padding="16px 16px 16px 24px"
          width={boxWidth}
        >
          <Layout.Stack alignItems="left" space={0}>
            <Text
              color="$primary1"
              data-testid="external-vision-card-heading"
              fontWeight="$bold"
              size={mobileScreen ? '$md' : 20.25}
            >
              {title}
            </Text>
            <Text
              color="$gray7"
              data-testid="external-vision-card-content"
              fontWeight={mobileScreen ? '$normal' : '$medium'}
              size={mobileScreen ? '$sm' : '$md'}
            >
              {message}
            </Text>
            <StyledVisionCareButton
              after={
                isAbsoluteUrl(url) ? (
                  <IconMaterial
                    color="$white"
                    data-testid="external-link-icon"
                    icon="open_in_new"
                  />
                ) : undefined
              }
              data-testid="cns-vision-redirect-button"
              href={url}
              onClick={handleOnClick}
            >
              {buttonTitle}
            </StyledVisionCareButton>
          </Layout.Stack>
        </StyledVisionCareBox>
      </ContentWrapper>
    </ContainerForAllSectionsStyled>
  );
};
