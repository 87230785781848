import { ProviderTypes } from '../Constants';

export const toShowKSBannerOnProviderDetailsAbout = (
  providerDetails,
  currentMember,
  ksTinValues
) => {
  const { providerType } = providerDetails;
  const { tin } = providerDetails?.providerLocations[0];
  const { isKelseySeyboldPlan } = currentMember;
  return (
    isKelseySeyboldPlan &&
    ksTinValues?.split(',').includes(tin) &&
    (providerType.toLowerCase() === ProviderTypes.MEDICALGROUP.toLowerCase() ||
      providerType.toLowerCase() === ProviderTypes.PRACTITIONER.toLowerCase())
  );
};
