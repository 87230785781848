import { styled } from '@abyss/web/tools/styled';

export const PageContainer = styled('div', {
  padding: '0px',
  backgroundColor: '$mapViewContainerBgColor',
});

export const AlertBannerWrapper = styled('div', {
  maxWidth: '1200px',
  margin: '0 auto',
  padding: '0 0 1px',
  '@media (max-width: 1248px)': {
    margin: '0 0 0 24px',
    maxWidth: 'none',
    width: '100%',
  },
});

export const MapViewWrapper = styled('div', {
  maxWidth: '1440px',
  marginLeft: 'calc((100vw - 1200px)/2) ',
  marginRight: '24px',
  padding: '24px 0',

  '@media (max-width: 1248px)': {
    marginLeft: '24px',
    marginRight: '24px',
  },
  '@screen < $md': {
    marginLeft: '16px',
    marginRight: '16px',
    padding: '16px 0',
  },
  '@screen < $sm': {
    padding: '0',
    margin: '0',
  },
});

export const MapViewWrapperFullWidth = styled('div', {
  marginLeft: '96px',
  padding: '24px 0',

  '@media (max-width: 1920px)': {
    marginLeft: '48px',
  },
  '@media (max-width: 1440px)': {
    marginLeft: '24px',
  },
});

export const EnhancePaginationMobileStyles = {
  '.abyss-c-PJLV-ihNPJpp-css': {
    '&:last-child': {
      position: 'sticky',
      bottom: '0',
      zIndex: '999',
      backgroundColor: 'white',
    },
  },
};
