import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { Popover } from '@abyss/web/ui/Popover';
import { Text } from '@abyss/web/ui/Text';

export const TierInfoBox = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px',
  borderRadius: '8px 8px 0px 0px',
  marginLeft: '8px',
  backgroundColor: '#F2F8E6',
  height: '24px',
  padding: '4px $sm',
  minWidth: '120px',
  width: 'fit-content',
});

export const TierCardText = styled(Text, {
  marginLeft: '4px',
  '&.abyss-text-root': {
    textAlign: 'center',
    whiteSpace: 'nowrap',
    color: '#4B4D4F',
    fontSize: '12.64px',
    fontWeight: '$bold',
    lineHeight: '16px ',
  },
});
export const SnackContainer = styled('div', {
  display: 'flex',
  '@screen < $md': {
    height: '100%',
  },
});

export const SnackAvatarContainer = styled('div', {
  position: 'relative',
  paddingBottom: '0px',
});

export const SnackInfoCard = styled(Flex, {
  variants: {
    isFixes: {
      true: {
        boxShadow: 'none',
        border: 'none',
      },
    },
    isSnackCardWithExternalLink: {
      true: {
        cursor: 'pointer',
      },
    },
    isLoading: {
      true: {
        '@screen < $sm': {
          maxHeight: '176px',
        },
      },
    },
  },
  width: '192px',
  height: '222px',
  gap: '0px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
  border: ' 1px solid #E5E5E6',
  padding: '16px 8px',
  borderRadius: '8px',
  flexWrap: 'nowrap',
  '.abyss-flex-root': {
    textAlign: 'center',
  },
  '@screen < $md': {
    minHeight: '132px',
  },
  '@screen < $sm': {
    width: '151px',
    height: '201px',
    padding: '12px 6px',
    minHeight: '132px',
  },
});

export const Avatar = styled(Flex, {
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$snackCardAvatarBackgroundColor',
  width: '72px',
  height: '72px',
  borderRadius: '50%',
  overflow: 'hidden',
  '@screen < $sm': {
    width: '60px',
    height: '60px',
  },
});

export const slideStyleSnack = {
  'abyss-slide-container': {
    backgroundColor: '$white',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
    border: ' 1px solid #E5E5E6',

    visibility: 'visible',
    borderRadius: '8px',
    height: 'auto',
  },
};

export const slideStyle = (i) => ({
  'abyss-slide-container': {
    backgroundColor: '$white',
    marginLeft: i === 0 ? '0px' : '20px',
  },
});

export const mixedResultsSlideStyle = (
  i: number,
  count: number,
  isExpandViewAllEnabled: boolean = false
) => ({
  'abyss-slide-container': {
    backgroundColor: '$white',
    marginLeft: i === 0 ? '0px' : '16px',
    marginRight: isExpandViewAllEnabled
      ? 0
      : count === 2 && i === 0
      ? '16px'
      : '0px',
  },
});

export const slideContainerStyle = {
  'abyss-slide-content-wrapper': {
    padding: '0',
    visibility: 'visible',
    '>div': {
      height: '',
    },
  },
};

export const carouselStyle = {
  'abyss-carousel-root': {
    '@screen < $sm': {
      zIndex: 0,
    },
  },
  'abyss-carousel-swiper-container': {
    display: 'flex',
    willChange: 'transform',
    marginLeft: '0',
    marginRight: '0',
  },
  'abyss-carousel-slide-container': {
    position: 'relative',
    width: '180px',
    gap: '16px',
    alignItems: 'end',
    paddingBottom: '50px',
    '@screen < $sm': {
      width: '144px',
    },
  },
  'abyss-carousel-minimal-controls': {
    marginTop: '12px',
    padding: '0px',
    height: '20px',
    position: 'absolute',
    bottom: '0',
    right: '0',
    left: '0',
    'button.abyss-carousel-minimal-pagination-container': {
      padding: ' 0 4px 0 4px',
      display: 'block',
      width: 'auto',
    },
  },
  'abyss-carousel-minimal-nav-icon-right': {
    width: '20px',
    height: '20px',
  },
  'abyss-carousel-minimal-nav-container-right': {
    width: '20px',
    height: '20px',
    padding: '0px',
    top: '-3px',
  },
  'abyss-carousel-minimal-nav-container-left': {
    width: '20px',
    height: '20px',
    padding: '0px',
    top: '-3px',
  },

  'abyss-carousel-minimal-nav-icon-left': {
    width: '20px',
    height: '20px',
  },
  'abyss-carousel-minimal-pagination-container': {
    padding: '0 8px 0 8px',
  },
};

export const SnackDescription = styled(Flex, {
  lineHeight: '16px',
  textAlign: 'center',
});

export const Miles = styled(Flex, {
  cursor: 'pointer',
  justifyContent: 'center',
  gap: '2px',
});

export const MilesContent = styled('button', {
  display: 'flex',
  cursor: 'pointer',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '2px',
  height: '18px',
});

export const SnackName = styled('div', {
  '.abyss-link-root': {
    paddingTop: '8px',
    lineHeight: '20px !important',
    width: '148px',
    paddingBottom: '8px',
    fontSize: '$md !important',
    fontWeight: 600,
    wordBreak: 'break-word',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    '@screen < $sm': {
      fontSize: '14.22px !important',
      fontWeight: 700,
      width: '114px',
    },
  },
});

export const SnackDetails = styled(Flex, {
  marginTop: '-2px',
  gap: '8px',
});

export const MilesText = styled(Text, {
  color: '$milesTextColor !important',
  fontSize: '$extraSmallText !important',
  fontWeight: 700,
});

export const SnackRole = styled('div', {
  lineHeight: '16px !important',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'inline-block',
  width: '148px',
  maxHeight: '32px',
  fontSize: '12px',
  fontWeight: '$boldFW',
  position: 'relative',
  bottom: '6px',
  '@screen < $sm': {
    lineHeight: '14px !important',
    width: '114px',
  },
});

export const NoRatingText = styled(Text, {
  fontSize: '$extraSmallText !important',
  fontWeight: 500,
  fontStyle: 'italic',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  lineHeight: '16px !important',
  '@screen < $sm': {
    width: '120px',
  },
});

export const PopoverContainer = styled('div', {
  position: 'relative',
  top: '4px',
  '.abyss-popover-content': {
    zIndex: '9 !important',
  },
});

export const SnackCardPopoverContainer = styled('div', {
  position: 'relative',
  top: '$popoverContainerTop',
  '.abyss-popover-content': {
    zIndex: '9 !important',
  },
});

export const DistanceDrawerStyles = {
  'abyss-modal-content-container': {
    borderRadius: '20px 20px 0 0',
    height: 'auto !important',
  },
  'abyss-modal-body': {
    margin: '16px 24px 16px 24px',
    color: '#4B4D4F',
  },
  'abyss-modal-close-button': { margin: '8px 10px 0 0' },
  'abyss-modal-content-title': {
    paddingTop: '2px',
    fontFamily: '$primary !important',
    fontSize: '20.25px !important',
    color: '#323334 !important',
    lineHeight: '24px !important',
    fontWeight: '700 !important',
  },
  'abyss-modal-header-container': {
    padding: '16px 24px 0px 24px',
  },
};

export const TierOnePopover = styled(Popover, {
  '&.abyss-popover-content': {
    position: 'fixed',
    zIndex: '9 !important',
  },
});

export const displayVirtualVisitStyle = {
  'abyss-flex-root': {
    border: '1px solid $white',
    display: 'flex',
    borderRadius: '100px',
    background: '$primary1',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px',
    width: '24px',
    height: '24px',
    position: 'absolute',
    top: '0px',
    right: '-8px',
    zindex: 1,
  },
};

export const TierOneSnackCardPopoverStyles = {
  '.abyss-popover-content-container': {
    whiteSpace: 'pre-line',
    color: '$gray8',
  },
};

export const SnackCardWithExternalLinkIcon = styled(Flex, {
  height: '24px',
  width: '100%',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  alignContent: 'flex-end',
  alignSelf: 'stretch',
});

export const SnackCardsWithExternalLink = styled(Flex, {
  marginTop: '4px',
  minHeight: '72px',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'center',
  alignSelf: 'stretch',
  alignItems: 'center',
  '@screen < $sm': {
    height: '36px',
  },
});

export const SnackCardWithExternalLinkText = styled(Button, {
  width: '100%',
  fontSize: '18px !important',
  fontWeight: 600,
  lineHeight: '24px !important',
  '&.abyss-button-root': {
    border: 'none',
    borderRadius: '0px',
    textOverflow: 'ellipsis',
    paddingLeft: '0px',
    paddingRight: '0px',
    height: 'auto',
    '&:active, &:focus, &:hover': {
      backgroundColor: 'white',
    },
    display: 'grid',
    '& span': {
      overflowWrap: 'anywhere',
      display: '-webkit-box',
      '-webkit-line-clamp': '3',
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      color: '$primary1 !important',
    },
  },
});

export const SnackCardWithExternalLinkType = styled('div', {
  marginTop: '8px',
  color: '$gray7 !important',
  textAlign: 'center',
  width: '100%',
  fontSize: '12.64px !important',
  fontWeight: 500,
  lineHeight: '$sm !important',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  '-webkit-line-clamp': '1',
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
});

export const TerminationDateIndicatorText = {
  fontSize: '$sm',
  color: '#BD5000',
  padding: '0px',
  marginLeft: '4px',
  maxWidth: '85px',
  '@screen < $sm': {
    maxWidth: '73px',
    fontSize: '$xs',
  },
};
