import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Card } from '@abyss/web/ui/Card';
import { Carousel, Slide } from '@abyss/web/ui/Carousel';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { t } from 'i18next';

import { desktopOnly, phoneOnly } from '../ConstantsStyles';
import { LinkWithTracking } from '../LinkWithTracking/LinkWithTracking';
import { makeAllSlidesAriaHidden } from '../Utils/a11yUtils/a11yUtils';

export const OnlineSpecialtyGroupCard = ({ marketPlaceObj }) => {
  const desktopScreen = useMediaQuery(desktopOnly);
  const phoneScreen = useMediaQuery(phoneOnly);
  const cardCssStyles = {
    'abyss-card-root': {
      height: desktopScreen ? '250px' : phoneScreen ? '300px' : '250px',
      width: phoneScreen ? '100%' : '98%',
      display: 'flex',
      borderColor: '#F3F3F3F3',
      borderRadius: '4px',
      flexDirection: 'column',
      '.abyss-card-section:first-child': {
        flexGrow: 1,
      },
    },
  };

  const slides = marketPlaceObj.map((group) => (
    <Slide
      css={{
        'abyss-slide-container': {
          marginLeft: 0,
          background: 'transparent',
          paddingLeft: '1rem',
        },
      }}
    >
      <Slide.Container css={{ padding: 0 }}>
        <Card css={cardCssStyles}>
          <Card.Section>
            <Heading
              data-auto-testid={`${group.dntTitle}-heading`}
              data-testid={`${group.dntTitle}-heading`}
              display="h5"
              offset={1}
            >
              {t(group.dntTitle)}
            </Heading>
            <Text>{t(group.description)}</Text>
          </Card.Section>
          <Card.Section
            css={{
              borderTop: '0px !important',
              paddingBottom: '30px',
            }}
          >
            <Layout.Group
              css={{
                justifyContent: 'space-between',
              }}
            >
              <LinkWithTracking
                analyticsInfo={{
                  location: 'body:online specialty groups',
                  destinationUrl: `/${group.linkUrl}`,
                }}
                css={{
                  lineHeight: '15px',
                }}
                data-auto-testid={`${group.dntTitle}`}
                data-testid={`${group.dntTitle}`}
                isStandardAnchor
                onClick={() => window.open(group.linkUrl)}
                size="14.22px"
              >
                {t(group.linkText)}
                <IconMaterial css={{ marginLeft: '8px' }} icon="open_in_new" />
              </LinkWithTracking>

              <img
                alt={group.dntTitle}
                src={group.iconUrl}
                style={{
                  width: '100px',
                  backgroundImage: 'none',
                }}
              />
            </Layout.Group>
          </Card.Section>
        </Card>
      </Slide.Container>
    </Slide>
  ));

  return (
    <Carousel
      data-auto-testid="online-specialty-groups-carousel"
      data-testid="online-specialty-groups-carousel"
      key={marketPlaceObj.length}
      minimal
      nextSlideOnClick={() => makeAllSlidesAriaHidden()}
      noLoop
      prevSlideOnClick={() => makeAllSlidesAriaHidden()}
      slideIndexOnClick={() => makeAllSlidesAriaHidden()}
      slides={slides}
      // slidesPerView={1}
      slidesPerView={desktopScreen ? 3 : 1}
      variant="none"
    />
  );
};
